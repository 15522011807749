import {CardContent, Chip} from "@mui/material";
import Grid from "@mui/material/Grid/Grid";
import {DateTimeFormat, Filter, Now, PlainDateTime, PlainDateTimePicker, tryParseInstant} from "@variocube/app-ui";
import {getSupportedFormatLocale} from "@variocube/app-ui/esm/getSupportedFormatLocale";
import {createElement, useEffect, useMemo, useState} from "react";
import {useAsync} from "react-async-hook";
import {CubesProvider} from "../../domain/CubesProvider";
import {CubeSummary, DeliveryStatisticsFilter} from "../../domain/Delivery";
import {Site} from "../../domain/Site";
import {SitesProvider} from "../../domain/SitesProvider";
import {useLocalization} from "../../i18n";
import {CubeSelectInput} from "../CubeSelectInput";
import {SitesSelectInput} from "../SitesSelectInput";
import {useTenantId, useTenantUser, useTenantUserRole} from "../TenantContextProvider";

interface BookingFilterProps {
	value: DeliveryStatisticsFilter;
	onChange: (value: DeliveryStatisticsFilter) => any;
}

export const UserTimeZoneId = Now.zonedDateTimeISO().timeZone;

function parseDate(value: string | undefined): PlainDateTime | null {
	return tryParseInstant(value)
		?.toZonedDateTimeISO(UserTimeZoneId)
		.toPlainDateTime() ?? null;
}

function handleDateChange(
	field: keyof DeliveryStatisticsFilter,
	value: DeliveryStatisticsFilter,
	onChange: (value: DeliveryStatisticsFilter) => void,
) {
	return (date: PlainDateTime | null) => {
		onChange({
			...value,
			[field]: date
				?.toZonedDateTime(UserTimeZoneId)
				.toInstant()
				.toString(),
		});
	};
}

export function DashboardFilter({value, onChange}: BookingFilterProps) {
	const {t, s, language} = useLocalization();
	const tenantId = useTenantId();
	const {isAdmin} = useTenantUserRole();
	const user = useTenantUser();

	const [selectedSites, setSelectedSites] = useState<Site[]>([]);
	const [selectedCubes, setSelectedCubes] = useState<CubeSummary[]>([]);

	const {result: sites} = useAsync(SitesProvider.list, [tenantId]);
	const {result: cubes} = useAsync(async () => {
		if (isAdmin) {
			return CubesProvider.list(tenantId);
		} else {
			return CubesProvider.listSiteCubes(tenantId, user.siteId);
		}
	}, [tenantId]);

	const creationFrom = parseDate(value.creationFrom);
	const creationUntil = parseDate(value.creationUntil);
	const pickupFrom = parseDate(value.pickupFrom);
	const pickupUntil = parseDate(value.pickupUntil);
	const storageFrom = parseDate(value.storageFrom);
	const storageUntil = parseDate(value.storageUntil);

	const handleCreationFromChange = handleDateChange("creationFrom", value, onChange);
	const handleCreationUntilChange = handleDateChange("creationUntil", value, onChange);
	const handlePickupFromChange = handleDateChange("pickupFrom", value, onChange);
	const handlePickupUntilChange = handleDateChange("pickupUntil", value, onChange);
	const handleStorageFromChange = handleDateChange("storageFrom", value, onChange);
	const handleStorageUntilChange = handleDateChange("storageUntil", value, onChange);

	const formatter = useMemo(() => new DateTimeFormat(language ?? getSupportedFormatLocale("dateTime")), [language]);

	useEffect(() => {
		if (sites && cubes) {
			const relevantSites = value.siteIds
				.map((siteId) => sites.find((site) => site.siteId === siteId))
				.filter(Boolean) as Site[];

			const relevantCubes = value.cubeIds
				.map((id) => cubes.find((cube) => cube.hostname === id))
				.filter(Boolean) as CubeSummary[];

			setSelectedSites(relevantSites);
			setSelectedCubes(relevantCubes);
		}
	}, [sites, cubes]);

	useEffect(() => {
		onChange({
			...value,
			siteIds: selectedSites.map((site) => site.siteId),
			cubeIds: selectedCubes.map((cube) => cube.hostname),
		});
	}, [selectedSites, selectedCubes]);

	return (
		<Filter
			label={t("filter.title")}
			labels={s("filter")}
			active={[
				value.siteIds && value.siteIds.map((siteId) => (
					<Chip
						key={"site" + siteId}
						label={t(`dashboard.filter.site`) + ": " + sites?.find((site) => site.siteId === siteId)?.name}
						onDelete={() => onChange({...value, siteIds: value.siteIds.filter((id) => id !== siteId)})}
					/>
				)),
				value.siteIds && value.cubeIds.map((cubeId) => (
					<Chip
						key={"cube" + cubeId}
						label={t(`dashboard.filter.cube`) + ": "
							+ cubes?.find((cube) => cube.hostname === cubeId)?.name}
						onDelete={() => onChange({...value, cubeIds: value.cubeIds.filter((id) => id !== cubeId)})}
					/>
				)),
				creationFrom && (
					<Chip
						key={"from"}
						label={`${t("dashboard.filter.creationFrom")}: ${formatter.format(creationFrom)}`}
						onDelete={() => onChange({...value, creationFrom: undefined})}
					/>
				),
				creationUntil && (
					<Chip
						key={"creationUntil"}
						label={`${t("dashboard.filter.creationUntil")}: ${formatter.format(creationUntil)}`}
						onDelete={() => onChange({...value, creationUntil: undefined})}
					/>
				),
				pickupFrom && (
					<Chip
						key={"pickupFrom"}
						label={`${t("dashboard.filter.pickupFrom")}: ${formatter.format(pickupFrom)}`}
						onDelete={() => onChange({...value, pickupFrom: undefined})}
					/>
				),
				pickupUntil && (
					<Chip
						key={"pickupUntil"}
						label={`${t("dashboard.filter.pickupUntil")}: ${formatter.format(pickupUntil)}`}
						onDelete={() => onChange({...value, pickupUntil: undefined})}
					/>
				),
				storageFrom && (
					<Chip
						key={"storageFrom"}
						label={`${t("dashboard.filter.storageFrom")}: ${formatter.format(storageFrom)}`}
						onDelete={() => onChange({...value, storageFrom: undefined})}
					/>
				),
				storageUntil && (
					<Chip
						key={"storageUntil"}
						label={`${t("dashboard.filter.storageUntil")}: ${formatter.format(storageUntil)}`}
						onDelete={() => onChange({...value, storageUntil: undefined})}
					/>
				),
			]}
		>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12} md={6}>
						{cubes
							&& (
								<CubeSelectInput
									label={t("filterCubes")}
									options={cubes}
									values={selectedCubes}
									onChange={setSelectedCubes}
								/>
							)}
					</Grid>
					<Grid item xs={12} md={6}>
						{sites && (
							<SitesSelectInput
								label={t("filterSites")}
								options={sites}
								values={selectedSites}
								onChange={setSelectedSites}
							/>
						)}
					</Grid>
					<Grid item xs={12} md={6}>
						<PlainDateTimePicker
							fullWidth
							value={creationFrom}
							onChange={handleCreationFromChange}
							label={t("dashboard.filter.creationFrom")}
							locale={language}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<PlainDateTimePicker
							fullWidth
							value={creationUntil}
							onChange={handleCreationUntilChange}
							label={t("dashboard.filter.creationUntil")}
							locale={language}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<PlainDateTimePicker
							fullWidth
							value={pickupFrom}
							onChange={handlePickupFromChange}
							label={t("dashboard.filter.pickupFrom")}
							locale={language}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<PlainDateTimePicker
							fullWidth
							value={pickupUntil}
							onChange={handlePickupUntilChange}
							label={t("dashboard.filter.pickupUntil")}
							locale={language}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<PlainDateTimePicker
							fullWidth
							value={storageFrom}
							onChange={handleStorageFromChange}
							label={t("dashboard.filter.storageFrom")}
							locale={language}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<PlainDateTimePicker
							fullWidth
							value={storageUntil}
							onChange={handleStorageUntilChange}
							label={t("dashboard.filter.storageUntil")}
							locale={language}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Filter>
	);
}
