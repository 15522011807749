import {createElement, useMemo} from "react";
import {useLocalization} from "../../i18n";
import {Box, Button, CircularProgress, Stack, Typography} from "@mui/material";
import {useAsync, useAsyncCallback} from "react-async-hook";
import {BookmarksProvider} from "../../domain/BookmarksProvider";
import {useTenantId} from "../TenantContextProvider";
import {ErrorAlert} from "@variocube/app-ui";

export function BookmarkFixedPickupCode({bookmarkId}: { bookmarkId: string }) {
	const {t} = useLocalization();
	const tenantId = useTenantId();

	const {result: {fixedPickupCode} = {}, loading: bookmarkLoading, error: bookmarkError, execute: reload} = useAsync(async () =>
		BookmarksProvider.get(tenantId, bookmarkId)
		, [tenantId, bookmarkId]);

	const {execute: generateFixedPickupCode, loading: generateLoading, error: generateError} = useAsyncCallback(BookmarksProvider.generateFixedPickupCode);
	const {execute: deleteFixedPickupCode, loading: deleteLoading, error: deleteError} = useAsyncCallback(BookmarksProvider.deleteFixedPickupCode);

	function generateCode() {
		generateFixedPickupCode(tenantId, bookmarkId)
			.catch(console.error)
			.then(() => reload());
	}

	function deleteCode() {
		deleteFixedPickupCode(tenantId, bookmarkId)
			.catch(console.error)
			.then(() => reload());
	}

	const loading = useMemo(() => bookmarkLoading || generateLoading || deleteLoading, [bookmarkLoading, generateLoading, deleteLoading]);
	const error = useMemo(() => bookmarkError ?? generateError ?? deleteError, [bookmarkError, generateError, deleteError]);

	return (
		<div>
			<Typography variant="h6" mb={2}>{t('dispatch.fixedPickupCode')}</Typography>
			{loading && <CircularProgress size={24} />}
			{error !== undefined && <ErrorAlert error={error}></ErrorAlert>}
			{!loading && (
				<Stack
					direction="row"
					gap={2}
				>
					<Typography>{!!fixedPickupCode ? fixedPickupCode : t('cubes.boxes.disabled')}</Typography>
					<Button
						variant="outlined"
						size="small"
						onClick={generateCode}
					>
						{!!fixedPickupCode
							? t('dispatch.fixedPickupCodeRegenerate')
							: t('dispatch.fixedPickupCodeGenerate')
						}
					</Button>
					{!!fixedPickupCode && (
						<Button
							color="error"
							variant="outlined"
							size="small"
							onClick={deleteCode}
						>
							{t('dispatch.fixedPickupCodeDelete')}
						</Button>
					)}
				</Stack>
			)}
			<Box my={1} />
			<Typography color="textSecondary" variant="body2">{t('dispatch.fixedPickupCodeHelperText')}</Typography>
		</div>
	)
}
