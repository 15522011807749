import { Stack } from "@mui/material";
import {createElement, useEffect} from "react";
import Barcode from "react-barcode";

interface WrappedBarCodeProps {
	value: string;
	height: number;
	width: number;
	margin?: number;
	onRendered?: () => void;
}

/**
 * Determine if the string value only contains Standard ASCII letters.
 *
 * @param value The string value
 */
function isStandardAsciiString(value: string) {
	// The format is set to CODE128, so the max. HEX is 0x7F (127).
	// See https://github.com/lindell/JsBarcode/wiki/CODE128
	return /^[\x00-\x7F]+$/g.test(value);
}

export function WrappedBarCode({value, height, width, margin, onRendered}: WrappedBarCodeProps) {
	const canRender = isStandardAsciiString(value);

	useEffect(() => {
		setTimeout(() => {
			if (onRendered) onRendered();
		}, 300);
	}, []);

	return canRender
		? (
			<Stack
				width="100%"
				direction="row"
				justifyContent="center"
			>
				<Barcode
					renderer="canvas"
					format="CODE128"
					value={value}
					height={height}
					width={width}
					displayValue={false}
					margin={margin ?? 10}
				/>
			</Stack>
		)
		: <div />
}
