import {HelpButton} from "@variocube/app-ui";
import {createElement} from "react";
import {isTestSystem} from "../stage";
import {useTenantUserRole} from "./TenantContextProvider";

interface LHelpButtonProps {
	helpPage: string;
	helpAnchor: string;
	language: string;
}

export function LHelpButton(props: LHelpButtonProps) {
	const {helpPage, helpAnchor, language} = props;
	const {isSuperUser} = useTenantUserRole();

	if (!isTestSystem()) {
		return null;
	}

	return <HelpButton helpPage={helpPage} helpAnchor={helpAnchor} language={language} showEmpty={isSuperUser} />;
}
