import {Button, Dialog, DialogActions, Typography} from "@mui/material";
import {
	AuditTimeline,
	AuditTimelineItem,
	CodeBlock,
	ErrorAlert,
	Json,
	SpringPageable,
	useFlag,
} from "@variocube/app-ui";
import {createElement, Fragment} from "react";
import {useAsync} from "react-async-hook";
import {DeliveriesProvider} from "../../domain/DeliveriesProvider";
import {Delivery} from "../../domain/Delivery";
import {useLocalization} from "../../i18n";
import {DateComponent} from "../DateComponent";
import {useTenantId} from "../TenantContextProvider";
import {UserNameDisplay} from "../UserNameDisplay";

interface DeliveryAuditLogProps {
	delivery: Delivery;
}

const PAGEABLE: SpringPageable = {
	page: 0,
	size: 100,
};

export function DeliveryAuditLog({delivery}: DeliveryAuditLogProps) {
	const tenantId = useTenantId();
	const {hasKey, t} = useLocalization();
	const {loading, error, result} = useAsync(DeliveriesProvider.getAuditLog, [tenantId, delivery, PAGEABLE]);

	return (
		<AuditTimeline loading={loading}>
			{error && <ErrorAlert error={error} />}
			{result?.content.map(({type, message, actor, patch, data, created}) => {
				const key = `audit.type.${type}`;
				const translation = hasKey(key) ? t(key) : message;
				const actorDisplay = actor ? <UserNameDisplay username={actor} maxLen={20} /> : null;
				return (
					<AuditTimelineItem
						key={created}
						actor={actorDisplay}
						changes={
							<Fragment>
								{patch && <ShowDetailsButton changes={patch} />}
								{data && <ShowDetailsButton changes={data} />}
							</Fragment>
						}
						created={<DateComponent date={created} />}
						message={translation}
						connector
					/>
				);
			})}
		</AuditTimeline>
	);
}

export function ShowDetailsButton({changes}: { changes: any }) {
	const [open, setOpen, clearOpen] = useFlag(false);
	const {t} = useLocalization();
	return (
		<Fragment>
			<Button variant="text" color="inherit" onClick={setOpen}>
				{t("deliveries.auditLog.showDetails")}
			</Button>
			<Dialog open={open} onClose={clearOpen}>
				<Typography variant="body2">
					<CodeBlock>
						<Json data={changes} />
					</CodeBlock>
				</Typography>
				<DialogActions>
					<Button onClick={clearOpen} color="primary">
						{t("close")}
					</Button>
				</DialogActions>
			</Dialog>
		</Fragment>
	);
}
