import {Box, Card, CardContent, Skeleton, Typography, useTheme} from "@mui/material";
import {createElement, useMemo} from "react";
import {useAsync} from "react-async-hook";
import {Bar, BarChart, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {DeliveryStatisticsFilter} from "../../domain/Delivery";
import {DeliveryStatisticsProvider} from "../../domain/DeliveryStatisticsProvider";
import {useLocalization} from "../../i18n";
import {useTenantId} from "../TenantContextProvider";

interface DeliveryRecipientOverdueMetricProps {
	filter: DeliveryStatisticsFilter;
}

export function TagCountChart({
	filter,
}: DeliveryRecipientOverdueMetricProps) {
	const {t, hasKey} = useLocalization();
	const tenantId = useTenantId();
	const theme = useTheme();

	const {loading, result} = useAsync(
		DeliveryStatisticsProvider.getTagCount,
		[tenantId, filter],
	);

	const getRandomColor = () => {
		return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
	};

	const tickFormatter = useMemo(() => {
		return (tag: string) => {
			const localeKey = "tags." + tag;
			const resolvedTag = hasKey(localeKey) ? t(localeKey) : tag;

			const maxLength = 15;
			return resolvedTag.length > maxLength
				? `${resolvedTag.substring(0, maxLength)}...`
				: resolvedTag;
		};
	}, [hasKey, t]);

	return (
		<Card>
			<CardContent>
				<Typography variant="h3">{t("dashboard.tagsCount")}</Typography>
				<Box sx={{overflowX: "auto", overflowY: "hidden", mt: 1}}>
					<Box sx={{minWidth: "800px", height: 500, position: "relative"}}>
						{loading && <Skeleton variant="rectangular" width="100%" height="100%" />}

						{!loading && (!result || result.length === 0) && (
							<Typography
								variant="h6"
								sx={{
									textAlign: "center",
									position: "absolute",
									top: "50%",
									left: "50%",
									transform: "translate(-50%, -50%)",
								}}
							>
								{t("dashboard.noData")}
							</Typography>
						)}

						{!loading && result && result.length > 0 && (
							<ResponsiveContainer width="100%" height="100%">
								<BarChart data={result}>
									<Bar
										dataKey="tagCount"
										name={t("dashboard.tagsCount")}
										fill={theme.palette.primary.main}
									>
										{result?.map((_, index) => (
											<Cell key={`cell-${index}`} fill={getRandomColor()} />
										))}
									</Bar>
									<XAxis
										dataKey="tag"
										interval={0}
										angle={-90}
										dx={-8}
										textAnchor="end"
										height={180}
										tickFormatter={tickFormatter}
									/>
									<YAxis />
									<Tooltip
										contentStyle={{
											backgroundColor: theme.palette.background.default,
										}}
									/>
								</BarChart>
							</ResponsiveContainer>
						)}
					</Box>
				</Box>
			</CardContent>
		</Card>
	);
}
