import QRCode from "qrcode";
import {createElement, useEffect} from "react";
import { useAsync } from "react-async-hook";
import {Stack} from "@mui/material";

interface WrappedQRCode {
	value: string;
	size: number;
	onRendered?: () => void;
}

export function WrappedQRCode({value, size, onRendered}: WrappedQRCode) {
	const {result} = useAsync(() => QRCode.toDataURL(value, {width: size}), [value, size]);

	useEffect(() => {
		if (result) {
			setTimeout(() => {
				if (onRendered) onRendered();
			}, 300);
		}
	}, [result]);

	return (
		<Stack
			width="100%"
			direction="row"
			justifyContent="center"
		>
			<img src={result} alt={value}/>
		</Stack>
	);
}
