import {Grid} from "@mui/material";
import {createElement, Fragment, useMemo} from "react";
import {useAsync} from "react-async-hook";
import {DeliveryStatisticsFilter} from "../../domain/Delivery";
import {DeliveryStatisticsProvider} from "../../domain/DeliveryStatisticsProvider";
import {useLocalization} from "../../i18n";
import {useTenantId} from "../TenantContextProvider";
import {Metric} from "./Metric";

interface DeliveryRecipientOverdueMetricProps {
	filter: DeliveryStatisticsFilter;
}

export function DeliveryCountMetric({
	filter,
}: DeliveryRecipientOverdueMetricProps) {
	const {t} = useLocalization();
	const tenantId = useTenantId();

	const {loading: overAllCountLoading, result: overallCount} = useAsync(
		DeliveryStatisticsProvider.getCount,
		[tenantId, filter],
	);

	const {loading: countPerStateLoading, result: countPerState} = useAsync(
		DeliveryStatisticsProvider.getCountPerState,
		[tenantId, filter],
	);

	const pickupCount = useMemo(() => countPerState?.find((state) => state.state == "PickedUp"), [countPerState]);
	const cancelledCount = useMemo(() => countPerState?.find((state) => state.state == "Cancelled"), [countPerState]);
	const storedCount = useMemo(() => countPerState?.find((state) => state.state == "Stored"), [countPerState]);

	return (
		<Fragment>
			<Grid item xs={6} md={3}>
				<Metric label={t("deliveries.plural")} value={overallCount?.count} loading={overAllCountLoading} />
			</Grid>
			<Grid item xs={6} md={3}>
				<Metric
					label={t("deliveries.deliveryStates.PickedUp")}
					value={pickupCount?.count ?? 0}
					loading={countPerStateLoading}
				/>
			</Grid>
			<Grid item xs={6} md={3}>
				<Metric
					label={t("deliveries.deliveryStates.Cancelled")}
					value={cancelledCount?.count ?? 0}
					loading={countPerStateLoading}
				/>
			</Grid>
			<Grid item xs={6} md={3}>
				<Metric
					label={t("deliveries.deliveryStates.Stored")}
					value={storedCount?.count ?? 0}
					loading={countPerStateLoading}
				/>
			</Grid>
		</Fragment>
	);
}
