import {Delivery, Recipient, ReturnDelivery} from "../../domain/Delivery";
import {ColumnType} from "@variocube/app-ui";
import {useLocalization} from "../../i18n";
import {useNavigate} from "react-router-dom";
import {useTenantId} from "../TenantContextProvider";
import {TableCell, TableRow} from "@mui/material";
import {DeliveryStateDisplay} from "./DeliveryStateDisplay";
import {DeliveryTypeDisplay} from "./DeliveryTypeDisplay";
import {DeliveryTagsDisplay} from "./DeliveryTagsDisplay";
import {DeliveryConditionDisplay} from "./DeliveryConditionDisplay";
import {DateComponent} from "../DateComponent";
import {BooleanDisplay} from "../BooleanDisplay";
import {createElement} from "react";

interface DeliveryRowProps {
	delivery: Delivery;
	columns: ColumnType
}

export function DeliveryRow({delivery, columns}: DeliveryRowProps) {
	const {t} = useLocalization();
	const navigate = useNavigate();
	const tenantId = useTenantId();

	const isColumnActive = (name: string) => (columns[name] && columns[name].show);

	function generateRecipientDisplay(recipients: Recipient[]) {
		if (!recipients || recipients.length == 0) return "";
		return recipients
			.map(r => r.recipientName || r.email || r.phone || t("deliveries.recipients.unknown"))
			.join(", ");
	}

	function generateRecipientsDepartmentsDisplay(recipients: Recipient[]) {
		if (!recipients || recipients.length == 0) return "";
		return recipients
			.filter(r => r.department)
			.map(r => r.department)
			.join(", ");
	}

	function generateRecipientsBuildingsDisplay(recipients: Recipient[]) {
		if (!recipients || recipients.length == 0) return "";
		return recipients
			.filter(r => r.building)
			.map(r => r.building)
			.join(", ");
	}

	function handleDeliveryClicked(delivery: Delivery) {
		navigate(`/${tenantId}/deliveries/${delivery.id}`);
	}

	function returnDeliveryDisplay(r: ReturnDelivery) {
		return [r.description, r.deliveryNoteId, r.customerId, r.reasonForReturn]
			.filter((e) => e)
			.join(", ");
	}

	return (
		<TableRow hover onClick={() => handleDeliveryClicked(delivery)}>
			{isColumnActive("delivery.state") && (
				<TableCell>
					<DeliveryStateDisplay deliveryState={delivery.delivery.state} size="small" />
				</TableCell>
			)}
			{isColumnActive("delivery.deliveryType") && (
				<TableCell>
					<DeliveryTypeDisplay deliveryType={delivery.delivery.deliveryType} size="small" />
				</TableCell>
			)}
			{isColumnActive("delivery.tags") && (
				<TableCell>
					<DeliveryTagsDisplay tags={delivery.tags} size="small" maxTags={2} />
				</TableCell>
			)}
			{isColumnActive("delivery.parcelId") && (
				<TableCell style={{maxWidth: "200px", overflow: "hidden", textOverflow: "ellipsis"}}>
					{delivery.delivery.parcelId}
				</TableCell>
			)}
			{isColumnActive("delivery.condition") && (
				<TableCell>
					<DeliveryConditionDisplay value={delivery.delivery.condition} />
				</TableCell>
			)}
			{isColumnActive("delivery.weight") && <TableCell>{delivery.delivery.weight}</TableCell>}
			{isColumnActive("delivery.sender") && (
				<TableCell>
					{delivery.delivery.sender ?? delivery.sender?.name ?? delivery.sender?.email
						?? delivery.sender?.phone ?? delivery.sender?.employeeNumber}
				</TableCell>
			)}
			{isColumnActive("delivery.carrier") && <TableCell>{delivery.delivery.carrier}</TableCell>}
			{isColumnActive("delivery.notes") && (
				<TableCell>
					<span
						style={{
							textOverflow: "ellipsis",
							whiteSpace: "nowrap",
							maxWidth: 180,
							display: "block",
							overflow: "auto",
						}}
					>
						{delivery.notes?.map(n => n.note).join(", ")}
					</span>
				</TableCell>
			)}

			{isColumnActive("order.description")
				&& (
					<TableCell>
						{delivery.order && delivery.order.description
							&& <span>{delivery.order.description}</span>}
						{delivery.returnDelivery
							&& <span>{returnDeliveryDisplay(delivery.returnDelivery)}</span>}
					</TableCell>
				)}
			{isColumnActive("order.deliveryNoteId") && <TableCell>{delivery.order.deliveryNoteId}</TableCell>}
			{isColumnActive("order.orderId") && <TableCell>{delivery.order.orderId}</TableCell>}
			{isColumnActive("order.orderPositions") && (
				<TableCell>{delivery.order.orderPositions.map(p => p.positionLabel).join(", ")}</TableCell>
			)}
			{isColumnActive("order.attachments") && (
				<TableCell>{delivery.order.attachments.map(a => a.name).join(", ")}</TableCell>
			)}
			{isColumnActive("metadata.foreignId") && <TableCell>{delivery.metadata?.foreignId}</TableCell>}
			{isColumnActive("metadata.callbackUrl") && <TableCell>{delivery.metadata?.callbackUrl}</TableCell>}

			{isColumnActive("recipients") && <TableCell>{generateRecipientDisplay(delivery.recipients)}</TableCell>}
			{isColumnActive("recipientsDepartments") && (
				<TableCell>{generateRecipientsDepartmentsDisplay(delivery.recipients)}</TableCell>
			)}
			{isColumnActive("recipientsBuildings") && (
				<TableCell>{generateRecipientsBuildingsDisplay(delivery.recipients)}</TableCell>
			)}

			{isColumnActive("delivery.createdAt") && (
				<TableCell>
					<DateComponent date={delivery.delivery.createdAt} />
				</TableCell>
			)}
			{isColumnActive("delivery.createdBy") && <TableCell>{delivery.delivery.createdBy}</TableCell>}

			{isColumnActive("lastUpdatedAt") && (
				<TableCell>
					<DateComponent date={delivery.lastUpdatedAt} />
				</TableCell>
			)}
			{isColumnActive("lastUpdatedBy") && <TableCell>{delivery.lastUpdatedBy}</TableCell>}
			{isColumnActive("delivery.storedAt") && (
				<TableCell>
					{delivery.delivery.storedAt && <DateComponent date={delivery.delivery.storedAt} />}
				</TableCell>
			)}
			{isColumnActive("delivery.pickedUpAt") && (
				<TableCell>
					{delivery.delivery.pickedUpAt && <DateComponent date={delivery.delivery.pickedUpAt} />}
				</TableCell>
			)}

			{isColumnActive("receivingDepartment") && <TableCell>{delivery.receivingDepartment}</TableCell>}

			{isColumnActive("storage.storageTimeMax") && <TableCell>{delivery.storage?.storageTimeMax}</TableCell>}
			{isColumnActive("storage.hazardousGoods") && <TableCell>{delivery.storage?.hazardousGoods}</TableCell>}
			{isColumnActive("storage.destination") && <TableCell>{delivery.storage?.destination}</TableCell>}
			{isColumnActive("storage.destinationName") && <TableCell>{delivery.storage?.destinationName}</TableCell>}
			{isColumnActive("storage.destinationDescription") && (
				<TableCell>{delivery.storage?.destinationDescription}</TableCell>
			)}
			{isColumnActive("storage.storageKey") && <TableCell>{delivery.storage?.storageKey}</TableCell>}
			{isColumnActive("storage.storageCode") && <TableCell>{delivery.storage?.storageCode}</TableCell>}
			{isColumnActive("storage.manualHandoverRequired") && (
				<TableCell>
					{delivery.storage?.manualHandoverRequired !== undefined && (
						<BooleanDisplay value={delivery.storage?.manualHandoverRequired} />
					)}
				</TableCell>
			)}
		</TableRow>
	);
};
