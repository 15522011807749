import {Card, CardContent, Grid, Skeleton, Typography} from "@mui/material";
import {createElement} from "react";
import {useAsync} from "react-async-hook";
import {DeliveryState, DeliveryStatisticsFilter} from "../../domain/Delivery";
import {DeliveryStatisticsProvider} from "../../domain/DeliveryStatisticsProvider";
import {useLocalization} from "../../i18n";
import {useTenantId} from "../TenantContextProvider";

interface DeliveryRecipientOverdueMetricProps {
	filter: DeliveryStatisticsFilter;
}

export function DeliveryAverageStorageTimeMetric({filter}: DeliveryRecipientOverdueMetricProps) {
	const {t} = useLocalization();
	const tenantId = useTenantId();

	const {loading, result} = useAsync(
		DeliveryStatisticsProvider.getAverageStorageTime,
		[tenantId, filter],
	);

	const averageStorageTimePickedUp = result?.find((statistic) => statistic.state == "PickedUp")
		?? {state: DeliveryState.PickedUp};
	const averageStorageTimeManualHandover = result?.find((statistic) => statistic.state == "ManualHandover")
		?? {state: DeliveryState.ManualHandover};

	return (
		<Card style={{minHeight: "150px"}}>
			<CardContent>
				<Typography variant="h3">
					{t("dashboard.averageStorageTimeTitle")}
				</Typography>

				<Grid container spacing={2} style={{marginTop: 1}}>
					<DeliveryStatisticRow
						state={averageStorageTimePickedUp.state}
						averageStorageTimeInSeconds={averageStorageTimePickedUp.averageStorageTimeInSeconds}
						isLoading={loading}
					/>
					<DeliveryStatisticRow
						state={averageStorageTimeManualHandover.state}
						averageStorageTimeInSeconds={averageStorageTimeManualHandover.averageStorageTimeInSeconds}
						isLoading={loading}
					/>
				</Grid>
			</CardContent>
		</Card>
	);
}

function DeliveryStatisticRow({
	state,
	averageStorageTimeInSeconds,
	isLoading = false,
}: {
	state?: DeliveryState;
	averageStorageTimeInSeconds?: number;
	isLoading?: boolean;
}) {
	const {t} = useLocalization();

	const toDaysAndHours = (timeInSeconds: number) => {
		const days = Math.floor(timeInSeconds / (24 * 3600));
		const hours = Math.floor((timeInSeconds % (24 * 3600)) / 3600);
		return {days, hours};
	};

	const {days, hours} = toDaysAndHours(averageStorageTimeInSeconds ?? 0);
	const value = t("dashboard.averageStorageTimeValue", {days, hours});

	return (
		<Grid container item xs={12} alignItems="center">
			<Grid item xs={6}>
				{isLoading
					? <Skeleton variant="text" width="90%" height={20} />
					: state && <Typography variant="body1">{t(`deliveries.deliveryStates.${state}`)}</Typography>}
			</Grid>
			<Grid item xs={6}>
				{isLoading
					? <Skeleton variant="rounded" width={20} height={25} />
					: (
						<Typography variant="body1" fontWeight={"bold"}>
							{averageStorageTimeInSeconds ? value : t("dashboard.noData")}
						</Typography>
					)}
			</Grid>
		</Grid>
	);
}
