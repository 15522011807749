import {Box, Card, CardContent, Skeleton, Typography, useTheme} from "@mui/material";
import {DateTimeFormat} from "@variocube/app-ui";
import {getSupportedFormatLocale} from "@variocube/app-ui/esm/getSupportedFormatLocale";
import {createElement, useMemo} from "react";
import {useAsync} from "react-async-hook";
import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {DeliveryStatisticsFilter} from "../../domain/Delivery";
import {DeliveryStatisticsProvider} from "../../domain/DeliveryStatisticsProvider";
import {useLocalization} from "../../i18n";
import {useTenantId} from "../TenantContextProvider";

interface DeliveryRecipientOverdueMetricProps {
	filter: DeliveryStatisticsFilter;
}

export function OccupationsPerDayChart({
	filter,
}: DeliveryRecipientOverdueMetricProps) {
	const {t, language} = useLocalization();
	const tenantId = useTenantId();
	const theme = useTheme();

	const {loading, result} = useAsync(
		DeliveryStatisticsProvider.getOccupationsPerDay,
		[tenantId, filter],
	);

	const dateFormat = useMemo(
		() => new DateTimeFormat(language ?? getSupportedFormatLocale("dateTime")),
		[language],
	);

	return (
		<Card>
			<CardContent>
				<Typography variant="h3">{t("dashboard.occupiedBoxes")}</Typography>
				<Box sx={{overflowX: "auto", overflowY: "hidden", mt: 1}}>
					<Box sx={{minWidth: "800px", height: 500, position: "relative"}}>
						{loading && <Skeleton variant="rectangular" width="100%" height="100%" />}

						{!loading && (!result || result.length === 0) && (
							<Typography
								variant="h6"
								sx={{
									textAlign: "center",
									position: "absolute",
									top: "50%",
									left: "50%",
									transform: "translate(-50%, -50%)",
								}}
							>
								{t("dashboard.noData")}
							</Typography>
						)}

						{!loading && result && result.length > 0 && (
							<ResponsiveContainer width="100%" height="100%">
								<LineChart data={result}>
									<Line
										dataKey="occupationCount"
										name={t("dashboard.occupationCount")}
										stroke={theme.palette.primary.main}
									/>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis
										dataKey="date"
										tickFormatter={(value) => dateFormat.format(new Date(value))}
									/>
									<YAxis />
									<Tooltip
										contentStyle={{
											backgroundColor: theme.palette.background.default,
										}}
										labelFormatter={(label) => dateFormat.format(new Date(label))}
									/>
								</LineChart>
							</ResponsiveContainer>
						)}
					</Box>
				</Box>
			</CardContent>
		</Card>
	);
}
