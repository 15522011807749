import {Company, Recipient} from "./Delivery";

export enum DispatchType {
	DryCleaning = "DryCleaning",
	Department = "Department",
	Person = "Person",
	DocumentDestruction = "DocumentDestruction",
	Outbound = "Outbound",
	Other = "Other",
}

export interface Bookmark {
	id: string;
	type: DispatchType;
	label: string;
	description: string;
	cubeId?: string;
	siteId?: string;
	requireConfirmation: boolean;
	requireInternalRecipient: boolean;
	requireRecipientAddress: boolean;
	requireDescription: boolean;
	requireDispatchDetails: boolean;
	enableAnonymousDelivery: boolean;
	recipients: Recipient[];
	redirects: Recipient[];
	companies: Company[];
	visibleFor: string[];
	costCenterPattern?: string;
	requireAuthorization: boolean;
	authorizationProviderHint?: string;
	fixedPickupCode?: string;
}

export interface BookmarkRequest extends Bookmark {
}
