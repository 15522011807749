import {Box, Checkbox, FormControlLabel, Typography} from "@mui/material";
import {createElement, Fragment, useCallback} from "react";
import {useLocalization} from "../../i18n";
import {ColumnType} from "@variocube/app-ui";

interface DeliveryColumnFilterProps {
	columns: ColumnType;
	baseColumns: ColumnType;
	onColumnChange: (cols: ColumnType) => void;
}

export function DeliveryColumnFilter({columns, baseColumns, onColumnChange}: DeliveryColumnFilterProps) {
	const {t} = useLocalization();

	const renderColumnCheckbox = useCallback((key: keyof typeof baseColumns) => {
		return (
			(columns && columns[key])
				? (
					<FormControlLabel
						key={"column-setting-" + key}
						label={columns[key].name}
						color="primary"
						control={
							<Checkbox
								checked={columns[key].show}
								onChange={e =>
									onColumnChange({...columns, [key]: {...columns[key], show: e.target.checked}})}
							/>
						}
					/>
				)
				: null
		)
	}, [columns, baseColumns])

	return (
		<Fragment>
			<Typography variant="overline">{t("delivery")}</Typography>
			<Box my={1} />
			{[
				"delivery.state",
				"delivery.deliveryType",
				"delivery.tags",
				"delivery.parcelId",
				"delivery.condition",
				"delivery.weight",
				"delivery.sender",
				"delivery.carrier",
				"delivery.createdAt",
				"delivery.createdBy",
				"lastUpdatedAt",
				"lastUpdatedBy",
				"delivery.storedAt",
				"delivery.pickedUpAt",
				"receivingDepartment",
				"recipients",
				"recipientsDepartments",
				"recipientsBuildings",
				"delivery.notes",
			].map(renderColumnCheckbox)}
			<Box my={3} />

			<Typography variant="overline">
				{t("deliveries.order.singular")}
			</Typography>
			<Box my={1} />
			{[
				"order.description",
				"order.deliveryNoteId",
				"order.orderId",
				"order.orderPositions",
				"order.attachments",
			].map(renderColumnCheckbox)}
			<Box my={3} />

			<Typography variant="overline">
				{t("deliveries.thirdParty.singular")}
			</Typography>
			<Box my={1} />
			{[
				"metadata.foreignId",
				"metadata.callbackUrl",
			].map(renderColumnCheckbox)}
			<Box my={3} />

			<Typography variant="overline">
				{t("deliveries.storage.singular")}
			</Typography>
			<Box my={1} />
			{[
				"storage.storageTimeMax",
				"storage.hazardousGoods",
				"storage.destination",
				"storage.destinationName",
				"storage.destinationDescription",
				"storage.storageKey",
				"storage.storageCode",
				"storage.manualHandoverRequired",
			].map(renderColumnCheckbox)}
			<Box my={3} />
		</Fragment>
	)
}
