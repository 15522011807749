import {createQueryString} from "@variocube/app-ui";
import {Api} from "../Api";
import {
	DeliveryAverageStorageTimeStatistic,
	DeliveryCountPerStateStatistic,
	DeliveryCountStatistic,
	DeliveryOccupationsPerDayStatistic,
	DeliveryRecipientOverdueStatistic,
	DeliveryStatisticsFilter,
	DeliveryTagCountStatistic,
} from "./Delivery";

export class DeliveryStatisticsProvider {
	static async getOverdueRecipientStatistics(
		tenantId: string,
		filter: DeliveryStatisticsFilter,
	): Promise<DeliveryRecipientOverdueStatistic[]> {
		let response = await Api.GET(
			`tenants/${tenantId}/delivery-statistics/overdue-recipients?${createQueryString(filter)}`,
		);
		return response as DeliveryRecipientOverdueStatistic[];
	}

	static async getCount(
		tenantId: string,
		filter: DeliveryStatisticsFilter,
	): Promise<DeliveryCountStatistic> {
		let response = await Api.GET(
			`tenants/${tenantId}/delivery-statistics/count?${createQueryString(filter)}`,
		);
		return response as DeliveryCountStatistic;
	}

	static async getCountPerState(
		tenantId: string,
		filter: DeliveryStatisticsFilter,
	): Promise<DeliveryCountPerStateStatistic[]> {
		let response = await Api.GET(
			`tenants/${tenantId}/delivery-statistics/count-per-state?${createQueryString(filter)}`,
		);
		return response as DeliveryCountPerStateStatistic[];
	}

	static async getAverageStorageTime(
		tenantId: string,
		filter: DeliveryStatisticsFilter,
	): Promise<DeliveryAverageStorageTimeStatistic[]> {
		let response = await Api.GET(
			`tenants/${tenantId}/delivery-statistics/average-storage-time-per-state?${createQueryString(filter)}`,
		);
		return response as DeliveryAverageStorageTimeStatistic[];
	}

	static async getOccupationsPerDay(
		tenantId: string,
		filter: DeliveryStatisticsFilter,
	): Promise<DeliveryOccupationsPerDayStatistic[]> {
		let response = await Api.GET(
			`tenants/${tenantId}/delivery-statistics/occupations-per-day?${createQueryString(filter)}`,
		);
		return response as DeliveryOccupationsPerDayStatistic[];
	}

	static async getTagCount(
		tenantId: string,
		filter: DeliveryStatisticsFilter,
	): Promise<DeliveryTagCountStatistic[]> {
		let response = await Api.GET(
			`tenants/${tenantId}/delivery-statistics/tag-count?${createQueryString(filter)}`,
		);
		return response as DeliveryTagCountStatistic[];
	}
}
