import {Alert, Button, Checkbox, Grid, List, ListItem, ListItemText, Paper, Typography} from "@mui/material";
import {ContainerLayout, TextField} from "@variocube/app-ui";
import {createElement, Fragment, useReducer, useState} from "react";
import {ManualHandover} from "../components/deliveries/ManualHandover";
import {HelmetTitleWrapper} from "../components/HelmetTitleWrapper";
import {Loading} from "../components/Loading";
import {useTenant, useTenantId} from "../components/TenantContextProvider";
import {DeliveriesProvider} from "../domain/DeliveriesProvider";
import {Delivery, DeliveryState} from "../domain/Delivery";
import {useLocalization} from "../i18n";
import {gs} from "../theme";
import {shorten} from "../tools";
import {BarCodeScanner} from "./BarCodeScanner";

/** Attention, we need a global list here because the <BarcodeScannerComponent /> somehow resets the state of local variables. */
let globalList: { delivery: Delivery; checked: boolean }[] = [];
let globalLastBarcode: string | undefined = undefined;

enum Steps {
	Scan,
	Recipient,
	Success,
}

let step = Steps.Scan;

export function BarCodeMobileScanner() {
	const debug = false;
	const {t} = useLocalization();
	const tenantId = useTenantId();
	const tenant = useTenant();

	const [barCode, setBarCode] = useState<string>();
	const [loading, setLoading] = useState<boolean>(false);
	const [info, setInfo] = useState<string>("");
	const [, forceUpdate] = useReducer(x => x + 1, 0);

	function onChecked(value: boolean, index: number) {
		globalList[index].checked = value;
		forceUpdate();
	}

	const processBarCode = async (barcode: string) => {
		if (debug) {
			console.log(`Camera provided barcode ${barcode}, comparing to last barcode ${barCode}`);
		}
		if (barcode == globalLastBarcode) {
			console.log(`Ignoring duplicate scan of barcode ${barcode}`);
			return;
		}
		setLoading(true);
		globalLastBarcode = barcode;
		if (debug) {
			console.log(`Camera provided barcode, attempting lookup ${barcode}`);
		}
		await addRow(barcode);
		setLoading(false);
	};

	async function addRow(code?: string) {
		setInfo("");
		if (code && code.length && globalList.findIndex(e => e.delivery.storage?.storageCode === code) == -1) {
			try {
				let deliveryId = await DeliveriesProvider.findByStorageCode(tenantId, code);
				let delivery: Delivery = await DeliveriesProvider.get(tenantId, deliveryId);
				let state = delivery.delivery.state;
				if (state != DeliveryState.Created && state != DeliveryState.ManualHandoverRequired && state != DeliveryState.Cancelled) {
					setInfo(t("barcode.wrongState"));
				} else {
					globalList.push({delivery, checked: true});
					setBarCode("");
				}
			} catch (ex) {
				setInfo(t("barcode.notFound", {storageCode: code}));
			}
		}
	}

	function onKeyDown(e: any) {
		setInfo("");
		if (e.keyCode == 13) {
			addRow(barCode);
		}
	}
	return (
		<ContainerLayout margin={0}>
			<HelmetTitleWrapper pageTitle={t("deliveries.manualHandover.title")} />
			<Grid container spacing={gs}>
				<Grid item xs={12}>
					<Typography variant="h2">{t("deliveries.manualHandover.title")}</Typography>
				</Grid>
				{step == Steps.Scan && (
					<Fragment>
						<Grid item xs={12}>
							<Typography variant="h5">{t("barcode.scan")}</Typography>
						</Grid>
						<Grid item xs={12}>
							<Paper>
								<TextField
									fullWidth
									placeholder={"Barcode"}
									value={barCode || ""}
									onChange={setBarCode}
									onKeyDown={onKeyDown}
								>
								</TextField>
							</Paper>
						</Grid>
						<Grid item xs={12}>
							<BarCodeScanner
								onBarCode={(result) => processBarCode(result.getText())}
							/>
						</Grid>
						{globalList.length > 0 && (
							<Grid item xs={12}>
								<Paper>
									<List>
										{globalList.map((deli, index) => (
											<ListItem key={index}>
												<Checkbox
													size="small"
													checked={deli.checked}
													onChange={(value) => onChecked(value.target.checked, index)}
												/>
												<ListItemText
													primary={shorten(
														deli.delivery.delivery.parcelId
															|| deli.delivery.order.description
															|| deli.delivery.id,
														35,
													)}
													secondary={shorten(
														deli.delivery.recipients.map(r => r.recipientName).join(", "),
														45,
													)}
												/>
											</ListItem>
										))}
									</List>
								</Paper>
							</Grid>
						)}
						{loading && (
							<Grid item xs={12}>
								<Loading />
							</Grid>
						)}
						<Grid item xs={12}>
							<Grid container spacing={gs}>
								{info.length > 0 && (
									<Grid item xs={12}>
										<Paper>
											<Alert severity="warning">{info}</Alert>
										</Paper>
									</Grid>
								)}
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Grid container justifyContent="flex-end">
								<Grid item>
									<Button
										variant="contained"
										disabled={globalList.length == 0}
										onClick={() => {
											step = Steps.Recipient;
											forceUpdate();
										}}
									>
										{t("next")}
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Fragment>
				)}
				{(step == Steps.Recipient && globalList.length) && (
					<Grid item>
						<ManualHandover
							tenantId={tenantId}
							deliveryIds={globalList.filter(x => x.checked).map(x => x.delivery.id)}
							onCancel={() => {
								step = Steps.Scan;
								forceUpdate();
							}}
							onConfirm={() => {
								step = Steps.Success;
								forceUpdate();
							}}
							options={tenant.manualHandoverOptions}
							orderDescription={""}
						/>
					</Grid>
				)}
				{step == Steps.Success && (
					<Fragment>
						<Grid item xs={12}>
							<Grid container spacing={gs}>
								<Grid item xs={12}>
									<Paper>
										<Alert severity="success">
											{t("deliveries.manualHandover.success", {no: globalList.length})}
										</Alert>
									</Paper>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Grid container justifyContent="flex-end">
								<Grid item>
									<Button
										variant="contained"
										onClick={() => {
											step = Steps.Scan;
											globalList = [];
											forceUpdate();
										}}
									>
										{t("close")}
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Fragment>
				)}
			</Grid>
		</ContainerLayout>
	);
}
