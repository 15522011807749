import {Alert, Button, Dialog, DialogContent, DialogTitle, Grid, useMediaQuery, useTheme} from "@mui/material";
import {Now, PlainDateTime, PlainDateTimePicker} from "@variocube/app-ui";
import {createElement, useCallback, useState} from "react";
import {DeliveriesProvider} from "../../domain/DeliveriesProvider";
import {Delivery} from "../../domain/Delivery";
import {useLocalization} from "../../i18n";
import {gs} from "../../theme";
import {useTenant, useTenantId} from "../TenantContextProvider";

interface ChangeAllowPickupUntilDialogProps {
	delivery: Delivery;
	open: boolean;
	onConfirm: (delivery: Delivery) => void;
	onCancel: () => void;
}

export function UpdateAllowPickupUntilDialog({
	delivery,
	open,
	onConfirm,
	onCancel,
}: ChangeAllowPickupUntilDialogProps) {
	const {t} = useLocalization();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const tenant = useTenant();
	const tenantId = useTenantId();

	const defaultPlainDateTime = Now.plainDateTimeISO().add({hours: tenant.maxStorageTime});

	const [allowPickupUntilDate, setAllowPickupUntilDate] = useState<PlainDateTime>(defaultPlainDateTime);
	const [submitting, setSubmitting] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);

	const handleConfirm = useCallback(async () => {
		setSubmitting(true);
		try {
			const updatedDelivery = await DeliveriesProvider.updateAllowPickupUntil(
				tenantId,
				delivery.id,
				allowPickupUntilDate,
			);
			onConfirm(updatedDelivery);
		} catch (err) {
			console.error(err);
			setError(t("deliveries.updateAllowPickupUntil.error"));
		} finally {
			setSubmitting(false);
		}
	}, [tenantId, delivery.id, allowPickupUntilDate, onConfirm]);

	const handleDateChange = useCallback(
		(value: PlainDateTime | null) => {
			setAllowPickupUntilDate(value ?? defaultPlainDateTime);
		},
		[defaultPlainDateTime],
	);

	const handleCloseError = useCallback(() => {
		setError(null);
	}, []);

	return (
		<Dialog fullScreen={fullScreen} open={open} onClose={onCancel}>
			<DialogTitle>{t("deliveries.updateAllowPickupUntil.title")}</DialogTitle>
			<DialogContent>
				<Grid container spacing={gs}>
					<Grid item xs={12}>
						<PlainDateTimePicker value={allowPickupUntilDate} onChange={handleDateChange} />
					</Grid>
					<Grid item>
						<ActionButtons
							onCancel={onCancel}
							onConfirm={handleConfirm}
							loading={submitting}
						/>
					</Grid>
					{error && (
						<Grid item xs={12}>
							<Alert severity="error" onClose={handleCloseError}>
								{error}
							</Alert>
						</Grid>
					)}
				</Grid>
			</DialogContent>
		</Dialog>
	);
}

interface ActionButtonsProps {
	onCancel: () => void;
	onConfirm: () => void;
	loading: boolean;
}

function ActionButtons({onCancel, onConfirm, loading}: ActionButtonsProps) {
	const {t} = useLocalization();
	return (
		<Grid container spacing={gs}>
			<Grid item>
				<Button
					variant="outlined"
					onClick={onCancel}
					disabled={loading}
				>
					{t("cancel")}
				</Button>
			</Grid>
			<Grid item>
				<Button
					color="primary"
					variant="contained"
					onClick={onConfirm}
					disabled={loading}
				>
					{t("confirm")}
				</Button>
			</Grid>
		</Grid>
	);
}
