import {Card, CardContent, Grid, Skeleton, Typography} from "@mui/material";
import {createElement} from "react";
import {useAsync} from "react-async-hook";
import {DeliveryStatisticsFilter} from "../../domain/Delivery";
import {DeliveryStatisticsProvider} from "../../domain/DeliveryStatisticsProvider";
import {useLocalization} from "../../i18n";
import {useTenantId} from "../TenantContextProvider";

interface DeliveryRecipientOverdueMetricProps {
	filter: DeliveryStatisticsFilter;
}

export function DeliveryRecipientOverdueMetric({
	filter,
}: DeliveryRecipientOverdueMetricProps) {
	const {t} = useLocalization();
	const tenantId = useTenantId();

	const {loading, result} = useAsync(
		DeliveryStatisticsProvider.getOverdueRecipientStatistics,
		[tenantId, filter],
	);

	return (
		<Card style={{minHeight: "250px"}}>
			<CardContent>
				<Typography variant="h3">
					{t("dashboard.top5OverdueRecipients")}
				</Typography>

				<Grid container spacing={2} style={{marginTop: 1}}>
					{loading
						&& Array.from({length: 5}).map((_, idx) => <DeliveryStatisticRow key={idx} isLoading />)}

					{!loading
						&& result?.map((statistic) => (
							<DeliveryStatisticRow
								key={statistic.recipient}
								recipient={statistic.recipient}
								overdueCount={statistic.overdueCount}
							/>
						))}

					{!loading && result?.length === 0 && (
						<Grid item>
							<Typography variant="body1" align="center">
								{t("dashboard.noData")}
							</Typography>
						</Grid>
					)}
				</Grid>
			</CardContent>
		</Card>
	);
}

function DeliveryStatisticRow({
	recipient,
	overdueCount,
	isLoading = false,
}: {
	recipient?: string;
	overdueCount?: number;
	isLoading?: boolean;
}) {
	return (
		<Grid container item xs={12} alignItems="center">
			<Grid item xs={6}>
				{isLoading
					? <Skeleton variant="text" width="90%" height={20} />
					: <Typography variant="body1">{recipient}</Typography>}
			</Grid>
			<Grid item xs={6}>
				{isLoading
					? <Skeleton variant="rounded" width={20} height={25} />
					: <Typography variant="body1" fontWeight={"bold"}>{overdueCount}</Typography>}
			</Grid>
		</Grid>
	);
}
