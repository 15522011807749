import {
	Alert,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	LinearProgress,
	Typography,
} from "@mui/material";
import {SpringPageable} from "@variocube/app-ui";
import {createElement, Fragment, useState} from "react";
import {AuditLogFilter, useAuditLog} from "../../domain/audit-log-provider";
import {useLocalization} from "../../i18n";

interface AuditExportButtonProps {
	centerId: string;
	filter: AuditLogFilter;
}

export function AuditExportButton({centerId, filter}: AuditExportButtonProps) {
	const {t} = useLocalization();
	const {list} = useAuditLog();

	const [progress, setProgress] = useState(0);
	const [error, setError] = useState<Error>();

	async function handleExport() {
		const handle = await window.showSaveFilePicker({
			suggestedName: `audit-log-${centerId}.json`,
		});

		setProgress(1);

		try {
			const pageable: SpringPageable = {size: 1000, page: 0};
			let page = await list(centerId, pageable, filter);
			const {content = [], totalPages = 1} = page;

			const writableStream = await handle.createWritable();
			await writableStream.write("[");
			await concatArray(writableStream, content);

			if (totalPages > 1) {
				for (let i = 1; i < totalPages; i++) {
					pageable.page = i;
					page = await list(centerId, pageable, filter);
					await writableStream.write(",");
					await concatArray(writableStream, content);
					const p = ((i + 1) * 100) / totalPages;
					setProgress(p);
				}
			}

			await writableStream.write("]");
			await writableStream.close();

			setProgress(100);
		} catch (err) {
			console.error("Failed to process", err);
		}
	}

	async function concatArray<T>(stream: FileSystemWritableFileStream, content: T[]) {
		const data = JSON.stringify(content);
		await stream.write(data.substring(1, data.length - 1));
	}

	function handleClose() {
		setProgress(0);
		setError(undefined);
	}

	return (
		<Fragment>
			<Button variant="contained" onClick={handleExport}>
				{t("audit.export")}
			</Button>
			<Dialog
				open={progress > 0}
				maxWidth="sm"
				fullWidth
			>
				<DialogTitle>
					{t("audit.export")}
				</DialogTitle>
				<DialogContent>
					{progress !== 100 && (
						<Box my={2}>
							<Typography gutterBottom>{t("audit.exportInProgress")}</Typography>
							<LinearProgress variant="determinate" value={progress} />
						</Box>
					)}
					{error && (
						<Alert severity="error">
							{error.message}
						</Alert>
					)}

					{progress === 100 && (
						<Alert severity="success">
							{t("audit.exportCompleted")}
						</Alert>
					)}
				</DialogContent>
				{(progress === 100 || error) && (
					<DialogActions>
						<Button onClick={handleClose}>{t("close")}</Button>
					</DialogActions>
				)}
			</Dialog>
		</Fragment>
	);
}
