import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	FormControlLabel,
	Grid,
	MenuItem,
	Paper,
	TextField,
	Typography,
} from "@mui/material";
import { BreadcrumbItem, Breadcrumbs } from "@variocube/app-ui";
import { ContainerLayout } from "@variocube/app-ui";
import { createElement, Fragment, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { BookmarkRequest, DispatchType } from "../../domain/Bookmark";
import { BookmarksProvider } from "../../domain/BookmarksProvider";
import { Cube } from "../../domain/Cube";
import { CubesProvider } from "../../domain/CubesProvider";
import { Recipient } from "../../domain/Delivery";
import { Site } from "../../domain/Site";
import { SitesProvider } from "../../domain/SitesProvider";
import { useLocalization } from "../../i18n";
import { gs } from "../../theme";
import { BoxedPaper } from "../BoxedPaper";
import { BreadcrumbRouterLink } from "../BreadcrumbRouterLink";
import { CubeSelectInput } from "../cubes/CubeSelectInput";
import { HelmetTitleWrapper } from "../HelmetTitleWrapper";
import { Loading } from "../Loading";
import { SiteSelectInput } from "../SiteSelectInput";
import { useTenantId } from "../TenantContextProvider";
import { DispatchCompaniesList } from "./DispatchCompaniesList";
import { DispatchRecipientsList } from "./DispatchRecipientsList";
import { VisibleForRadios } from "./VisibleForRadios";
import { SimpleCheckBox } from "../SimpleCheckBox";
import { LHelpButton } from "../LHelpButton";
import {BookmarkFixedPickupCode} from "./BookmarkFixedPickupCode";

export const DispatchEdit = () => {
	const { bookmark } = useParams<"bookmark">();
	const navigate = useNavigate();
	const tenantId = useTenantId();
	const { t, e } = useLocalization();
	const [form, setForm] = useState<BookmarkRequest>();
	const [recipients, setRecipients] = useState<Recipient[]>([]);
	const [redirects, setRedirects] = useState<Recipient[]>([]);
	const [cube, setCube] = useState<Cube | undefined>();
	const [site, setSite] = useState<Site | undefined>(undefined);
	const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

	useEffect(() => {
		if (tenantId && bookmark) {
			BookmarksProvider.get(tenantId, bookmark)
				.then((response) => {
					setForm(response);
					setRecipients(response.recipients);
					setRedirects(response.redirects);
					if (response.cubeId) {
						CubesProvider.get(tenantId, response.cubeId).then(setCube);
					}
					if (response.siteId) {
						SitesProvider.get(tenantId, response.siteId).then(setSite);
					}
				});
		}
	}, [tenantId, bookmark]);

	const handleSave = async () => {
		if (form) {
			try {
				await BookmarksProvider.update(tenantId, form.id, {
					...form,
					cubeId: cube ? cube.hostname : undefined,
					siteId: site ? site.siteId : undefined,
					recipients,
					redirects,
				});
				navigate(`/${tenantId}/dispatch`);
			} catch (error) {
				console.error(error);
				alert("Error: Cannot save bookmark.");
			}
		}
	};

	const canSubmit = useMemo(() => {
		return (form && !!form.label);
	}, [form, recipients]);

	async function handleDelete() {
		setShowDeleteDialog(false);
		if (form) {
			try {
				await BookmarksProvider.delete(tenantId, form.id);
				navigate(`/${tenantId}/dispatch`);
			} catch (error) {
				console.error(error);
				alert("Error: Cannot delete bookmark.");
			}
		}
	}

	return (
		<ContainerLayout>
			<HelmetTitleWrapper pageTitle={t("dispatch.plural")} />
			<Grid container spacing={gs}>
				<Grid item xs={12}>
					<Breadcrumbs>
						<BreadcrumbRouterLink to={`/${tenantId}/dispatch`}>{t("dispatch.plural")}</BreadcrumbRouterLink>
						<BreadcrumbItem>{t("edit")}</BreadcrumbItem>
					</Breadcrumbs>
				</Grid>
				{!form && (
					<Grid item xs={12}>
						<Loading />
					</Grid>
				)}
				{form && (
					<Fragment>
						<Grid item xs={12}>
							<Typography variant="h2">{form.label}</Typography>
						</Grid>
						<Grid item xs={12}>
							<BoxedPaper>
								<Grid container spacing={gs}>
									<Grid item xs={12}>
										<Grid container spacing={gs}>
											<Grid item sm={4} xs={12}>
												<TextField
													variant="outlined"
													fullWidth
													select
													label={t("dispatch.type")}
													value={form.type}
													onChange={ev => setForm({ ...form, type: ev.target.value as any })}
												>
													{Object.values(DispatchType).map(t => (
														<MenuItem key={t} value={t}>{e("dispatch.types", t)}</MenuItem>
													))}
												</TextField>
											</Grid>
											<Grid item sm={8} xs={12}>
												<TextField
													variant="outlined"
													fullWidth
													label={t("dispatch.label")}
													value={form.label}
													onChange={ev => setForm({ ...form, label: ev.target.value })}
													error={!form.label.trim()}
													helperText={!form.label.trim() ? t("required") : undefined}
												/>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12}>
										<TextField
											variant="outlined"
											fullWidth
											label={t("dispatch.description")}
											value={form.description}
											onChange={ev => setForm({ ...form, description: ev.target.value })}
											multiline
											rows={4}
										/>
									</Grid>
									<Grid item xs={6}>
										<SiteSelectInput value={site?.siteId} onChange={setSite} />
									</Grid>
									<Grid item xs={6}>
										<CubeSelectInput value={cube} onChange={setCube} allowUndefined />
									</Grid>
									<Grid item xs={12}>
										<VisibleForRadios
											visibleFor={form.visibleFor}
											onChange={(visibleFor) => setForm({ ...form, visibleFor: visibleFor })}
										/>
									</Grid>
									<Grid item xs={12}>
										<Grid container spacing={1}>
											<Grid item xs={12}>
												<FormControlLabel
													control={
														<Checkbox
															checked={form.requireConfirmation}
															onChange={ev =>
																setForm({
																	...form,
																	requireConfirmation: ev.target.checked,
																})}
														/>
													}
													label={t("dispatch.requireConfirmation")}
												/>
											</Grid>
											<Grid item xs={12}>
												<FormControlLabel
													control={
														<Checkbox
															checked={form.requireInternalRecipient}
															onChange={ev =>
																setForm({
																	...form,
																	requireInternalRecipient: ev.target.checked,
																})}
														/>
													}
													label={t("dispatch.requireInternalRecipient")}
												/>
											</Grid>
											<Grid item xs={12}>
												<FormControlLabel
													control={
														<Checkbox
															checked={form.requireRecipientAddress}
															onChange={ev =>
																setForm({
																	...form,
																	requireRecipientAddress: ev.target.checked,
																})}
														/>
													}
													label={t("dispatch.requireRecipientAddress")}
												/>
											</Grid>
											<Grid item xs={12}>
												<FormControlLabel
													control={
														<Checkbox
															checked={form.requireDescription}
															onChange={ev =>
																setForm({
																	...form,
																	requireDescription: ev.target.checked,
																})}
														/>
													}
													label={t("dispatch.requireDescription")}
												/>
											</Grid>
											<Grid item xs={12}>
												<FormControlLabel
													control={
														<Checkbox
															checked={form.requireDispatchDetails}
															onChange={ev =>
																setForm({
																	...form,
																	requireDispatchDetails: ev.target.checked,
																})}
														/>
													}
													label={t("dispatch.requireDispatchDetails")}
												/>
											</Grid>
											<Grid item xs={12}>
												<FormControlLabel
													control={
														<Checkbox
															checked={form.enableAnonymousDelivery}
															onChange={ev =>
																setForm({
																	...form,
																	enableAnonymousDelivery: ev.target.checked,
																})}
														/>
													}
													label={t("dispatch.enableAnonymousDelivery")}
												/>
											</Grid>

											<Grid item xs={12}>
												<Box>
													<SimpleCheckBox
														label={t("returns.requiresAuthorization")}
														checked={form.requireAuthorization}
														onChange={requireAuthorization =>
															setForm({
																...form,
																requireAuthorization,
															})}
													/>
													<LHelpButton helpPage="Tenant_Settings" helpAnchor="Return_Deliveries" language="" />
												</Box>

												{form.requireAuthorization && (
													<Box>
														<TextField
															fullWidth
															variant="outlined"
															label={t("returns.authorizationHintLabel")}
															placeholder="Please, scan your library QR Code!"
															value={form.authorizationProviderHint}
															onChange={ev =>
																setForm({
																	...form,
																	authorizationProviderHint: ev.target.value,
																})}
														/>
													</Box>
												)}
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12}>
										<Box p={2}>
											<Divider />
										</Box>
									</Grid>
									<Grid item xs={12}>
										<DispatchRecipientsList
											label={t("recipients.plural")}
											description={t("dispatch.recipientsDescription")}
											list={recipients}
											onChange={setRecipients}
										/>
									</Grid>
									<Grid item xs={12}>
										<Box p={2}>
											<Divider />
										</Box>
									</Grid>
									<Grid item xs={12}>
										<DispatchRecipientsList
											label={t("dispatch.redirects")}
											description={t("dispatch.redirectsDescription")}
											list={redirects}
											onChange={setRedirects}
										/>
									</Grid>
									<Grid item xs={12}>
										<Box p={2}>
											<Divider />
										</Box>
									</Grid>
									<Grid item xs={12}>
										<DispatchCompaniesList
											label={t("dispatch.company")}
											description={t("dispatch.companyDescription")}
											list={form.companies}
											onChange={(r) => setForm({ ...form, companies: r })}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											fullWidth
											size="small"
											label="Regex Pattern"
											value={form.costCenterPattern}
											onChange={ev => setForm({ ...form, costCenterPattern: ev.target.value })}
										/>
									</Grid>
									<Grid item xs={12}>
										<Box p={2}>
											<Divider />
										</Box>
									</Grid>
									<Grid item xs={12} style={{ display: "flex", flexDirection: "row" }}>
										<Button
											variant="contained"
											color="primary"
											onClick={handleSave}
											disabled={!canSubmit}
										>
											{t("save")}
										</Button>
										<Box mx={1} style={{ flexGrow: 1 }} />
										<Button variant="outlined" onClick={() => navigate(-1)}>{t("cancel")}</Button>
									</Grid>
								</Grid>
							</BoxedPaper>
							<Box my={2} />
							{!!bookmark && (
								<Fragment>
									<BoxedPaper>
										<BookmarkFixedPickupCode bookmarkId={bookmark} />
									</BoxedPaper>
									<Box my={2} />
								</Fragment>
							)}
							<Paper>
								<Box p={2} display="flex" flexDirection="row" alignItems="center">
									<Box flexGrow={1}>
										<Typography>
											<strong>{t("warning.title")}</strong>
										</Typography>
										<Typography>{t("warning.hint")}</Typography>
									</Box>
									<Button variant="outlined" color="error" onClick={() => setShowDeleteDialog(true)}>
										{t("dispatch.delete.title")}
									</Button>
								</Box>
							</Paper>
							<Dialog open={showDeleteDialog} fullWidth maxWidth="sm">
								<DialogTitle>{t("dispatch.delete.title")}</DialogTitle>
								<DialogContent>{t("dispatch.delete.prompt")}</DialogContent>
								<DialogActions>
									<Button variant="contained" color="primary" onClick={() => handleDelete()}>
										{t("yes")}
									</Button>
									<Button onClick={() => setShowDeleteDialog(false)}>{t("no")}</Button>
								</DialogActions>
							</Dialog>
						</Grid>
					</Fragment>
				)}
			</Grid>
		</ContainerLayout>
	);
};
