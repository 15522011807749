import {Alert} from "@mui/lab";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";
import {PlainDateTime, PlainDateTimePicker} from "@variocube/app-ui";
import {createElement, useCallback, useEffect, useState} from "react";
import {Recipient} from "../../domain/Delivery";
import {OutOfOffice} from "../../domain/OutOfOffice";
import {RecipientSummary} from "../../domain/Recipients";
import {RecipientsProvider} from "../../domain/RecipientsProvider";
import {useLocalization} from "../../i18n";
import {convertPlainDateTime} from "../../utils/plainDateTimeUtils";
import {BoxedDivider} from "../BoxedDivider";
import {SimpleCheckBox} from "../SimpleCheckBox";
import {useTenantId} from "../TenantContextProvider";
import {RecipientSearchV2} from "./RecipientSearchV2";

interface OutOfOfficeDialogProps {
	outOfOffice?: OutOfOffice;
	open: boolean;
	onCancel: () => void;
	onSave?: (outOfOffice: OutOfOffice) => void;
}

export function OutOfOfficeDialog(props: OutOfOfficeDialogProps) {
	const {t} = useLocalization();
	const tenantId = useTenantId();

	const [active, setActive] = useState<boolean>();
	const [withTimeframe, setWithTimeframe] = useState<boolean>();

	const [recipients, setRecipients] = useState<Recipient[]>();
	const [redirectExisting, setRedirectExisting] = useState<boolean>();
	const [confirmed, setConfirmed] = useState(false);

	const [from, setFrom] = useState<PlainDateTime | null>(
		props?.outOfOffice?.from ? PlainDateTime.from(props.outOfOffice.from.substring(0, 19)) : null,
	);

	const [to, setTo] = useState<PlainDateTime | null>(
		props?.outOfOffice?.to ? PlainDateTime.from(props.outOfOffice.to.substring(0, 19)) : null,
	);

	useEffect(() => {
		(async () => {
			setActive(props.outOfOffice?.active);
			setFrom(props?.outOfOffice?.from ? PlainDateTime.from(props.outOfOffice.from.substring(0, 19)) : null);
			setTo(props?.outOfOffice?.to ? PlainDateTime.from(props.outOfOffice.to.substring(0, 19)) : null);
			setWithTimeframe(props.outOfOffice?.withTimeframe);
			if (props.outOfOffice?.substituteEmail) {
				var emails = props.outOfOffice?.substituteEmail.split(",");
				const allRecipients = [];
				for (var email of emails) {
					let recipient = await RecipientsProvider.get(tenantId, email);
					allRecipients.push(recipient);
				}

				setRecipients(allRecipients);
			}
			setRedirectExisting(props.outOfOffice?.redirectExisting);
		})();
	}, [props?.outOfOffice]);

	const handleRecipientChange = useCallback((r: RecipientSummary[] | undefined) => {
		setRecipients(r);
	}, []);

	function handleSave() {
		setConfirmed(true);
		let from_ = convertPlainDateTime(from);
		let to_ = convertPlainDateTime(to);
		if (props.onSave) {
			props.onSave({
				active,
				withTimeframe,
				substituteEmail: recipients?.map(r => r.email).join(","),
				from: from_,
				to: to_,
				redirectExisting,
			});
		}
	}

	return (
		<Dialog open={props.open} PaperProps={{sx: {maxWidth: "50%"}}}>
			<DialogTitle>
				{t("recipients.outOfOfficeAssistant.title")}
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<SimpleCheckBox
							label={t("recipients.outOfOfficeAssistant.active")}
							checked={active || false}
							onChange={setActive}
						/>
					</Grid>
					<Grid item xs={12}>
						<SimpleCheckBox
							disabled={!active}
							label={t("onlyDuringTimePeriod")}
							checked={withTimeframe || false}
							onChange={setWithTimeframe}
						/>
					</Grid>
					<Grid item xs={6}>
						<PlainDateTimePicker
							disabled={!active || !withTimeframe}
							fullWidth
							value={from ?? null}
							onChange={(val) => setFrom(val ?? null)}
						/>
					</Grid>
					<Grid item xs={6}>
						<PlainDateTimePicker
							disabled={!active || !withTimeframe}
							fullWidth
							value={to ?? null}
							onChange={(val) => setTo(val ?? null)}
						/>
					</Grid>
					<Grid item xs={12}>
						<BoxedDivider p={2} />
					</Grid>
					<Grid item xs={12}>
						<RecipientSearchV2
							label={t("deliveries.create.recipientStep.selection")}
							multiple
							recipients
							values={recipients ?? []}
							onChange={handleRecipientChange}
							disabled={!active}
						/>
					</Grid>
					<Grid item xs={12}>
						<BoxedDivider p={2} />
					</Grid>
					<Grid item xs={12}>
						<SimpleCheckBox
							disabled={!active}
							label={t("recipients.redirectExistingDeliveries")}
							checked={redirectExisting}
							onChange={setRedirectExisting}
						/>
					</Grid>
					{active && confirmed && recipients?.length == 0
						&& (
							<Grid item xs={12}>
								<Alert severity={"error"}>
									{t("recipients.selectSubstitute")}
								</Alert>
							</Grid>
						)}
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					onClick={() => {
						setActive(props.outOfOffice?.active);
						props.onCancel();
					}}
				>
					{t("cancel")}
				</Button>
				<Button
					color="primary"
					variant="contained"
					onClick={handleSave}
					disabled={active && recipients && !(recipients?.length > 0)}
				>
					{t("confirm")}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
