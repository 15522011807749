import {Instant} from "@variocube/app-ui";
import {AppBoxFeature} from "./Cube";
import {DispatchDetails, PostalAddress} from "./Dispatch";
import {Shipment} from "./Shipments";

export enum DeleteAfterDeliveryType {
	None = "None",
	Recipient = "Recipient",
	RetentionDays = "RetentionDays",
	RetentionDaysCreated = "RetentionDaysCreated",
}

export enum DeliveryRecipientType {
	Original = "Original",
	Substitute = "Substitute",
	Forwarded = "Forwarded",
}

export interface Delivery {
	id: string;
	tenantId: string;
	metadata?: ForeignSystemMetadata;
	order: Order;
	sender?: Sender;
	recipients: Recipient[];
	externalRecipient?: PostalAddress;
	storage?: Storage;
	delivery: DeliveryMetadata;
	returnDelivery?: ReturnDelivery;
	tags?: string[];
	receivingDepartment?: string;
	shipment?: Shipment;
	deliveryDispatchDetails: DispatchDetails;
	notes?: DeliveryNote[];
	employeeId?: string;
	deleteAfter?: string;
	deleteAfterDeliveryType?: string;
	notifyRecipients: boolean;
	lastUpdatedAt?: string;
	lastUpdatedBy?: string;
}

export interface DeliveryKpi {
	createdAt?: string;
	storedAt?: string;
	pickedUpAt?: string;
	state: string;
	tags?: string[];
	recipients: Recipient[];
}

export interface ChangeHistory {
	createdBy?: string;
	createdAt: string;
	lastUpdatedBy?: string;
	lastUpdatedAt?: string;
}

export interface UpdateHistory {
	updatedAt: string;
	updatedBy?: string;
	what?: string;
}

export interface ReturnDelivery {
	description: string;
	state: string;
	storedAt: string;
	pickedUpAt: string;
	accessCode: string;
	deliveryNoteId?: string;
	customerId?: string;
	positionNumbers?: string;
	reasonForReturn?: string;
}

export interface DeliveryCreateMetadata {
	deliveryType?: DeliveryType;
	parcelId?: string;
	condition: DeliveryCondition;
	weight?: number;
	units?: string;
	sender?: string;
	carrier?: string;
	imageUrl?: string;
	state?: string;
}

export interface CreateDeliveryRequest {
	delivery?: DeliveryCreateMetadata;
	metadata?: ForeignSystemMetadata;
	order: Order;
	recipients: Recipient[];
	notifyRecipients: boolean;
	receivingDepartment?: string;
	storage?: Storage;
	images?: DeliveryImage[];
	tags?: string[];
}

export interface ForeignSystemMetadata {
	foreignId: string;
	callbackUrl: string;
}

export interface OrderPosition {
	positionId: string;
	positionLabel: string;
}

export interface Attachment {
	name: string;
	contentType: string;
	url: string;
}

export interface Order {
	orderId: string;
	deliveryNoteId?: string;
	description: string;
	orderPositions: OrderPosition[];
	attachments: Attachment[];
}

export interface Sender {
	centerId?: string;
	name: string;
	email?: string;
	phone?: string;
	employeeNumber?: string;
	locale?: string;
}

export interface Recipient {
	id?: string;
	centerId?: string;
	recipientName: string;
	email?: string;
	phone?: string;
	cubeId?: string;
	preferredCubeId?: string;
	department?: string;
	building?: string;
	pickupKey?: string;
	learnedPickupKey?: string;
	created?: string;
	pickedUp?: boolean;
	source?: string;
	employeeNumber?: string;
	position?: string;
	salutation?: string;
	deleteAfter?: string;
	receiveNotifications?: boolean;
	locale?: string;
	recipientType?: DeliveryRecipientType;
}

export interface RecipientMutation extends Omit<Recipient, "id" | "centerId" | "created" | "pickedUp" | "source"> {
}

export interface Company {
	name: string;
}

export interface CubeSummary {
	hostname: string;
	name: string;
	count?: number;
}

export interface Storage {
	storageTimeMax?: number;
	hazardousGoods?: string;
	destination?: string;
	destinationName?: string;
	destinationDescription?: string;
	storageKey?: string;
	storageCode?: string;
	manualHandoverRequired?: boolean;
	reserveBox: string[];
	reserveBoxFeature?: AppBoxFeature;
}

export interface DeliveryMetadata {
	deliveryId: string;
	state: DeliveryState;
	deliveryType: DeliveryType;
	parcelId?: string;
	trackingUrl?: string;
	condition: DeliveryCondition;
	weight?: number;
	units?: string;
	sender?: string;
	carrier?: string;
	cube: string;
	boxNumber?: string;
	boxNumbers?: string[];
	allowPickupUntil: number;
	allowPickupOverdueNotifiedAt: number;
	createdAt: string;
	createdBy: string;
	storedAt?: string;
	storedBy?: string;
	reminderSentAt?: string;
	pickedUpAt?: string;
	pickedUpBy?: string;
	checkedAt?: string;
	checkedBy?: string;
	accessCode: string;
	usedPickupKey?: string;
	numberOfItems: number;
	handedOverTo?: string;
}

export enum DeliveryState {
	Draft = "Draft",
	Created = "Created",
	ManualHandoverRequired = "ManualHandoverRequired",
	Reserved = "Reserved",
	Stored = "Stored",
	PickedUp = "PickedUp",
	Cancelled = "Cancelled",
	ManualHandover = "ManualHandover",
	Shipped = "Shipped",
	Returned = "Returned",
}

export enum DeliveryType {
	Inbound = "Inbound",
	Outbound = "Outbound",
}

export enum DeliveryCondition {
	Intact = "Intact",
	Opened = "Opened",
	Damaged = "Damaged",
	Destroyed = "Destroyed",
}

export interface DeliveryImage {
	image: string;
	imageType: string;
}

export enum DeliveryImageType {
	Recipient = "Recipient",
	Signature = "Signature",
}

export function recipientSecondaryText(r: Recipient, showPickupKey: boolean): string | undefined {
	const elements: string[] = [];
	if (r.email) {
		elements.push(r.email);
	}
	if (r.phone) {
		elements.push(r.phone);
	}
	if (r.building) {
		elements.push(r.building);
	}
	if (r.department) {
		elements.push(r.department);
	}
	if (showPickupKey && r.pickupKey) {
		elements.push(r.pickupKey);
	}
	if (r.learnedPickupKey) {
		elements.push(r.learnedPickupKey);
	}
	if (elements.length == 0) {
		return undefined;
	}
	return elements.join(", ");
}

export type DeliveryFilterRequest = {
	needle: string;
	siteIds: string[];
	cubeIds: string[];
	deliveryStates: DeliveryState[];
	deliveryTypes: DeliveryType[];
	deliveryConditions: DeliveryCondition[];
	creationTimeframe?: DeliveryFilterTimeframe;
	storageTimeframe?: DeliveryFilterTimeframe;
	pickupTimeframe?: DeliveryFilterTimeframe;
	tags: string[];
};

export type DeliveryStatisticsFilter = {
	siteIds: string[];
	cubeIds: string[];

	creationFrom?: string;
	creationUntil?: string;

	storageFrom?: string;
	storageUntil?: string;

	pickupFrom?: string;
	pickupUntil?: string;
};

export type DeliveryFilterTimeframe = {
	from?: string;
	until?: string;
};

export enum QueueStatus {
	Created = "Created",
	Failed = "Failed",
	Succeeded = "Succeeded",
}

export interface DeliveryQueueItem {
	uuid: string;
	status: QueueStatus;
	response: string;
	failureCount?: number;
	created: Instant;
	retry?: Instant;
	delivered?: Instant;
	expired?: Instant;
	deliveryId: string;
	deliveryState: DeliveryState;
	url: string;
	hostname: string;
	method: string;
	payload: string;
}

export type DeliveryLearnedDetails = {
	sender: string[];
	carrier: string[];
};

export interface Handover {
	handedOverTo?: string;
	signature?: string;
	notes?: string;
	employeeId?: string;
}

export interface DeliveryNote {
	id?: string;
	note?: string;
	changeHistory?: ChangeHistory;
}

export interface DeliveryRecipientOverdueStatistic {
	recipient?: string;
	overdueCount?: number;
}

export interface DeliveryCountStatistic {
	count: number;
}

export interface DeliveryCountPerStateStatistic {
	state?: DeliveryState;
	count: number;
}

export interface DeliveryAverageStorageTimeStatistic {
	state?: DeliveryState;
	averageStorageTimeInSeconds?: number;
}

export interface DeliveryAverageStorageTimeStatistic {
	state?: DeliveryState;
	averageStorageTimeInSeconds?: number;
}

export interface DeliveryOccupationsPerDayStatistic {
	date: string;
	occupationCount: number;
}

export interface DeliveryTagCountStatistic {
	tag: string;
	tagCount: number;
}
