import {Grid, Typography} from "@mui/material";
import {Breadcrumbs, ContainerLayout, Now, useStorage} from "@variocube/app-ui";
import {createElement} from "react";
import {DeliveryStatisticsFilter} from "../../domain/Delivery";
import {useLocalization} from "../../i18n";
import {gs} from "../../theme";
import {BreadcrumbRouterLink} from "../BreadcrumbRouterLink";
import {useTenantId} from "../TenantContextProvider";
import {DashboardFilter} from "./DashboardFilter";
import {DeliveryAverageStorageTimeMetric} from "./DeliveryAverageStorageTimeMetric";
import {DeliveryCountMetric} from "./DeliveryCountMetric";
import {DeliveryRecipientOverdueMetric} from "./DeliveryRecipientOverdueMetric";
import {OccupationsPerDayChart} from "./OccupationsPerDayChart";
import {TagCountChart} from "./TagCountChart";

export interface DeliveryData {
	createdAt: Date;
	storedAt?: Date;
	pickupAt?: Date;
	state: string;

	tags: string[];
	boxTypes: string[];
}

export const zonedDateTimeOneWeekAgo = Now.zonedDateTimeISO().subtract({days: 30});
const emptyFilter: DeliveryStatisticsFilter = {
	siteIds: [],
	cubeIds: [],

	creationFrom: zonedDateTimeOneWeekAgo.toString(),
	storageFrom: zonedDateTimeOneWeekAgo.toString(),
	pickupFrom: zonedDateTimeOneWeekAgo.toString(),
};

export function Dashboard() {
	const tenantId = useTenantId();
	const {t} = useLocalization();

	const [filter, setFilter] = useStorage<DeliveryStatisticsFilter>("Dashboard.filter", emptyFilter);

	return (
		<ContainerLayout>
			<Grid container spacing={gs}>
				<Grid item xs={12}>
					<Breadcrumbs>
						<BreadcrumbRouterLink to={`/${tenantId}/dashboard`}>
							{t("dashboard.singular")}
						</BreadcrumbRouterLink>
					</Breadcrumbs>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h2">{t("dashboard.singular")}</Typography>
				</Grid>

				<Grid item xs={12}>
					<DashboardFilter value={filter} onChange={setFilter} />
				</Grid>

				<DeliveryCountMetric filter={filter} />

				<Grid item xs={12} md={6}>
					<DeliveryAverageStorageTimeMetric filter={filter} />
				</Grid>

				<Grid item xs={12} md={6}>
					<DeliveryRecipientOverdueMetric filter={filter} />
				</Grid>

				<Grid item xs={12}>
					<OccupationsPerDayChart filter={filter} />
				</Grid>

				<Grid item xs={12}>
					<TagCountChart filter={filter} />
				</Grid>
			</Grid>
		</ContainerLayout>
	);
}
