import {Box, Card, CardContent, Skeleton, Typography} from "@mui/material";
import {createElement} from "react";
import {useLocalization} from "../../i18n";

interface MetricProps {
	label: string;
	value?: number | string;
	loading?: boolean;
}

export function Metric({label, value, loading}: MetricProps) {
	const {t} = useLocalization();

	return (
		<Card>
			<CardContent>
				<Typography variant="overline">{label}</Typography>
				<Box alignItems="end" display="flex" flexDirection="column" mt={2}>
					{loading ? <Skeleton variant="rounded" width={60} height={35} /> : (
						<Typography variant="h2">
							{value !== undefined && value ? value : t("dashboard.noData")}
						</Typography>
					)}
				</Box>
			</CardContent>
		</Card>
	);
}
